<template>
  <div class="subbmitions-page">
    <div v-if="$system.user.isAuthenticated" class="container">
      <!--Page title-->
      <Title :title="title" />
      <!--Search-->
      <div class="row">
        <b-input-group size="sm" class="mb-3 col-12 col-lg-3 p-0">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Enter search text"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
        <div class="col-2 col-lg-3 p-0 mb-3">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-10 col-lg-1 offset-lg-5 mb-3">
          <div class="flex-end">
            <b-icon-arrow-counterclockwise
              @click="getSubmissions"
              class="btn__refresh"
              v-b-tooltip.hover
              title="Refresh"
            />
          </div>
        </div>
      </div>
      <!--Submissions table-->
      <div v-if="!loading" class="row">
        <b-table
          class="table"
          hover
          striped
          :items="submissions"
          :fields="fieldsSubmissions"
          :filter="filter"
          :filter-included-fields="filterOn"
          responsive
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!--Account Name Router Link column-->
          <template #cell(AccountName)="row">
            <div>
              <template v-if="row.item.AccountName && row.item.AccountId">
                <router-link
                  :to="'account/' + row.item.AccountId"
                  v-b-tooltip.hover
                  title="Account information"
                  class="active-link-table"
                >
                  {{ row.item.AccountName }}
                </router-link>
              </template>
            </div>
          </template>

          <!--Link-->
          <template #cell(Link)="row">
            <div>
              <template
                v-if="row.item.Status == 'Approved' && row.item.offers.length"
              >
                <a
                  @click="openModalOffers(row.item.offers)"
                  class="active-link-table"
                  v-b-tooltip.hover
                  title="Show offers"
                >
                  Offers
                </a>
              </template>
              <template v-if="row.item.Status == 'Collecting Stips'">
                <a
                  v-if="row.item.Link"
                  target="_blank"
                  :href="row.item.Link"
                  class="active-link-table"
                >
                  {{ row.item.LinkName }}
                </a>
              </template>
            </div>
          </template>
        </b-table>

        <!-- Offers Modal -->
        <b-modal
          id="offers"
          title="Offers"
          centered
          hide-footer
          scrollable
          size="xl"
          @hide="closeModalOffers"
        >
          <div class="d-block table-modal-offers">
            <b-table
              class="table"
              hover
              striped
              :items="offers"
              :fields="fieldsOffers"
              responsive
            >
              <!--Offer link-->
              <template #cell(LinkName)="row">
                <div>
                  <template v-if="row.item.LinkName && row.item.Link">
                    <a
                      :href="row.item.Link"
                      target="_blank"
                      class="active-link-table"
                    >
                      {{ row.item.LinkName }}
                    </a>
                  </template>
                </div>
              </template>
              <!--Saving-->
              <template #cell(Saving)="row">
                <div>
                  <template v-if="row.item.Saving">
                    {{ row.item.Saving }}
                  </template>
                </div>
              </template>
              <!--Factor-->
              <template #cell(Factor)="row">
                <div>
                  <template v-if="row.item.Saving">
                    {{ row.item.Factor.toFixed(2) }}
                  </template>
                </div>
              </template>
              <!--Funded-->
              <template #cell(Funded)="row">
                <div v-if="row.item.Funded">
                  <template v-if="row.item.Funded">
                    {{ '$' + row.item.Funded }}
                  </template>
                </div>
              </template>
              <!--	Payback-->
              <template #cell(Payback)="row">
                <div v-if="row.item.Payback">
                  <template v-if="row.item.Payback">
                    {{ '$' + row.item.Payback }}
                  </template>
                </div>
              </template>
              <!--	Payment Amount-->
              <template #cell(PaymentAmount)="row">
                <div v-if="row.item.PaymentAmount">
                  <template v-if="row.item.PaymentAmount">
                    {{ '$' + row.item.PaymentAmount }}
                  </template>
                </div>
              </template>
            </b-table>
          </div>
        </b-modal>

        <div v-if="!submissions.length" class="col-12 flex-center">
          <p class="text-center p-5">No records found.</p>
        </div>
        <!--Table Pagination-->
        <div class="col-12 flex-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </div>
      <div v-else class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
    <div v-else class="container">
      <div class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import './SubmissionsPage.scss'
import SubmissionsService from '@/services/submissions/submissions.service'
import Title from '@/components/main-layout/title/Title.vue'
export default {
  name: 'SubmissionsPage',
  components: {
    Title,
  },

  metaInfo: {
    title: 'Submissions',
    titleTemplate: '%s | ISO Portal | Nexi ',
  },
  data() {
    return {
      loading: false,
      title: 'Submissions',
      offers: [],
      fieldsOffers: [
        {
          key: 'Name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'OfferDate',
          label: 'OfferDate',
          sortable: false,
        },
        {
          key: 'Funded',
          label: 'Funded',
          sortable: true,
        },
        {
          key: 'Payback',
          label: 'Payback',
          sortable: true,
        },
        {
          key: 'Factor',
          label: 'Factor',
          sortable: true,
        },
        {
          key: 'Term',
          label: 'Term',
          sortable: true,
        },
        {
          key: 'PaymentAmount',
          label: 'Payment Amount',
          sortable: true,
        },
        {
          key: 'Frequency',
          label: 'Frequency',
          sortable: true,
        },
        {
          key: 'Saving',
          label: 'Daily Saving',
          sortable: true,
        },
        {
          key: 'Points',
          label: 'Points',
          sortable: true,
        },
        {
          key: 'Product',
          label: 'Product',
          sortable: true,
        },

        {
          key: 'LinkName',
          label: 'Link',
          sortable: false,
        },
      ],
      submissions: [],
      fieldsSubmissions: [
        {
          key: 'AccountName',
          label: 'Account',
          sortable: true,
        },
        {
          key: 'CreatedDate',
          label: 'Created Date',
          sortable: false,
        },
        {
          key: 'Status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'Link',
          label: 'Link',
          sortable: false,
        },
        {
          key: 'DeclineReason',
          label: 'Reason',
          sortable: true,
        },
        {
          key: 'DeclineReasonsNotes',
          label: 'Notes',
          sortable: false,
        },
      ],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 40, 60, { value: 1000, text: 'Show all' }],
    }
  },
  computed: {
    auth() {
      return this.$system.user.isAuthenticated
    },
  },
  watch: {
    auth() {
      this.getSubmissions()
    },
  },
  created() {
    if (this.$system.user.isAuthenticated) {
      this.getSubmissions()
    } else this.$system.firstLoading()
  },
  methods: {
    // Get Submissions
    async getSubmissions() {
      try {
        this.loading = true
        const response = await SubmissionsService.getSubmissions(
          this.$system.user,
        )
        if (
          response &&
          response.status == 'success' &&
          response.body &&
          response.body.data
        ) {
          this.submissions = response.body.data
          if (response.body.isMain) {
            let field = {
              key: 'IsoRep',
              label: 'ISO REP',
              sortable: false,
            }
            this.fieldsSubmissions.unshift(field)
          }
          this.totalRows = this.submissions.length
          this.loading = false
        } else {
          this.loading = false
        }
      } catch (err) {
        this.loading = false
      }
    },
    // Open Modal Offers
    openModalOffers(offers) {
      this.offers = offers
      this.$bvModal.show('offers')
    },
    // Close Modal Offers
    closeModalOffers() {
      this.$bvModal.hide('offers')
      this.offers = []
    },
  },
}
</script>
